export default {
  data() {
    return {
      valid: true,
      isSubmit: false
    }
  },

  methods: {
    isRequiredField({ value, field, errorText }) {
      return (value && value !== '') || this.getRequiredFieldError(field) || errorText
    },

    getRequiredFieldError(field) {
      if (!field) {
        return null
      }

      return `Поле ${field} не может быть пустым`
    },

    async onSubmitForm() {
      await this.$refs.form.validate()

      if (!this.valid) {
        window.snackbar('Ошибки в форме', 'error')
        return
      }
      this.isSubmit = true
      this.onSubmit()
    },

    successSubmit({ message, routeName }) {
      if (message) {
        window.snackbar(message, 'info')
      }

      if (routeName) {
        this.$router.push({ name: routeName })
      }
    },

    afterSubmit() {
      this.isSubmit = false
    }
  }
}
